var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "widget-grid-content"
  }, [!_vm.dashboardLoaded ? _c('div', {
    staticClass: "content-loading"
  }, [_c('div', {
    staticClass: "loader"
  })]) : _vm.localWidgets && _vm.localWidgets.length < 1 ? _c('div', {
    staticClass: "no-results-wrapper"
  }, [_c('p', {
    staticClass: "heading-geo32 no-results"
  }, [_vm._v(" " + _vm._s(_vm.$t("NoWidgetsFound")) + " ")]), _c('p', {
    staticClass: "add-widget-button",
    on: {
      "click": _vm.addWidgets
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("AddWidgets")) + " ")])]) : _c('div', {
    staticClass: "widget-grid-container"
  }, [_vm.enlargedWidget ? _c('div', {
    staticClass: "overlay"
  }, [_c('Widget', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: _vm.closeEnlargedWidget,
      expression: "closeEnlargedWidget"
    }],
    staticClass: "enlargedWidget",
    style: _vm.activeWidgetOriginalSize,
    attrs: {
      "widget": _vm.enlargedWidget,
      "loaded": _vm.enlargedWidget.loaded
    },
    on: {
      "removeWidget": _vm.removeWidget
    },
    scopedSlots: _vm._u([{
      key: "widget-filters",
      fn: function () {
        var _vm$enlargedWidget, _vm$enlargedWidget$fi;
        return [(_vm$enlargedWidget = _vm.enlargedWidget) !== null && _vm$enlargedWidget !== void 0 && (_vm$enlargedWidget$fi = _vm$enlargedWidget.filters) !== null && _vm$enlargedWidget$fi !== void 0 && _vm$enlargedWidget$fi.includes('users') ? _c('div', {
          staticClass: "widget-filter"
        }, [_c('GroupOrUserSelect', {
          attrs: {
            "id": _vm.enlargedWidget._id
          },
          on: {
            "change": function (value) {
              return _vm.handleWidgetUsersChange(_vm.enlargedWidget._id, value);
            }
          },
          model: {
            value: _vm.selectedUsersForWidget[_vm.enlargedWidget._id],
            callback: function ($$v) {
              _vm.$set(_vm.selectedUsersForWidget, _vm.enlargedWidget._id, $$v);
            },
            expression: "selectedUsersForWidget[enlargedWidget._id]"
          }
        })], 1) : _vm._e()];
      },
      proxy: true
    }, {
      key: "widget-content",
      fn: function () {
        return [_c(_vm.enlargedWidget.component, {
          tag: "component",
          attrs: {
            "widget-id": _vm.enlargedWidget._id,
            "filtered-users": _vm.filteredUsersForWidget[_vm.enlargedWidget._id]
          },
          on: {
            "contentLoaded": _vm.contentLoaded
          }
        }), _c('div', {
          staticClass: "loader-container"
        }, [!_vm.enlargedWidget.loaded ? _c('div', {
          staticClass: "loader-center"
        }, [_c('div', {
          staticClass: "loader"
        })]) : _vm._e()])];
      },
      proxy: true
    }], null, false, 3353829188)
  })], 1) : _vm._e(), _c('draggable', {
    staticClass: "drag-area",
    attrs: {
      "list": _vm.localWidgets,
      "animation": 200
    },
    on: {
      "end": _vm.onDragEnd
    }
  }, _vm._l(_vm.localWidgets, function (widget) {
    return _c('div', {
      key: widget._id,
      style: {
        gridColumn: 'span ' + widget.size.width,
        gridRow: 'span ' + widget.size.height
      }
    }, [_c('Widget', {
      attrs: {
        "widget": widget,
        "loaded": widget.loaded
      },
      on: {
        "removeWidget": _vm.removeWidget,
        "enlargeWidget": _vm.enlargeWidget
      },
      scopedSlots: _vm._u([{
        key: "widget-filters",
        fn: function () {
          var _widget$filters;
          return [widget !== null && widget !== void 0 && (_widget$filters = widget.filters) !== null && _widget$filters !== void 0 && _widget$filters.includes('users') ? _c('div', {
            staticClass: "widget-filter"
          }, [_c('GroupOrUserSelect', {
            attrs: {
              "id": widget._id,
              "allow-only-first-level-data": _vm.allowOnlyFirstLevelData[widget._id]
            },
            on: {
              "change": function (value) {
                return _vm.handleWidgetUsersChange(widget._id, value);
              }
            },
            model: {
              value: _vm.selectedUsersForWidget[widget._id],
              callback: function ($$v) {
                _vm.$set(_vm.selectedUsersForWidget, widget._id, $$v);
              },
              expression: "selectedUsersForWidget[widget._id]"
            }
          })], 1) : _vm._e()];
        },
        proxy: true
      }, {
        key: "widget-content",
        fn: function () {
          return [_c(widget.component, {
            tag: "component",
            attrs: {
              "widget-id": widget._id,
              "filtered-users": _vm.filteredUsersForWidget[widget._id],
              "group-or-user": _vm.groupOrUser[widget._id]
            },
            on: {
              "updateFilterLevel": function ($event) {
                return _vm.handleFilterLevel(widget._id, $event);
              },
              "clearGroup": _vm.handleClearGroup,
              "contentLoaded": _vm.contentLoaded
            }
          }), _c('div', {
            staticClass: "loader-container"
          }, [!widget.loaded ? _c('div', {
            staticClass: "loader-center"
          }, [_c('div', {
            staticClass: "loader"
          })]) : _vm._e()])];
        },
        proxy: true
      }], null, true)
    })], 1);
  }), 0)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }