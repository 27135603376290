<template>
  <div class="widget-grid-content">
    <div class="content-loading" v-if="!dashboardLoaded">
      <div class="loader" />
    </div>
    <div
      v-else-if="localWidgets && localWidgets.length < 1"
      class="no-results-wrapper"
    >
      <p class="heading-geo32 no-results">
        {{ $t("NoWidgetsFound") }}
      </p>
      <p class="add-widget-button" @click="addWidgets">
        {{ $t("AddWidgets") }}
      </p>
    </div>
    <div class="widget-grid-container" v-else>
      <div v-if="enlargedWidget" class="overlay">
        <Widget
          v-click-outside="closeEnlargedWidget"
          :widget="enlargedWidget"
          :loaded="enlargedWidget.loaded"
          :style="activeWidgetOriginalSize"
          class="enlargedWidget"
          @removeWidget="removeWidget"
        >
          <template v-slot:widget-filters>
            <div
              v-if="enlargedWidget?.filters?.includes('users')"
              class="widget-filter"
            >
              <GroupOrUserSelect
                v-model="selectedUsersForWidget[enlargedWidget._id]"
                :id="enlargedWidget._id"
                @change="
                  (value) => handleWidgetUsersChange(enlargedWidget._id, value)
                "
              />
            </div>
          </template>
          <template v-slot:widget-content>
            <component
              :is="enlargedWidget.component"
              :widget-id="enlargedWidget._id"
              :filtered-users="filteredUsersForWidget[enlargedWidget._id]"
              @contentLoaded="contentLoaded"
            ></component>
            <div class="loader-container">
              <div v-if="!enlargedWidget.loaded" class="loader-center">
                <div class="loader"></div>
              </div>
            </div>
          </template>
        </Widget>
      </div>
      <draggable
        :list="localWidgets"
        @end="onDragEnd"
        :animation="200"
        class="drag-area"
      >
        <div
          v-for="widget in localWidgets"
          :key="widget._id"
          :style="{
            gridColumn: 'span ' + widget.size.width,
            gridRow: 'span ' + widget.size.height,
          }"
        >
          <Widget
            :widget="widget"
            :loaded="widget.loaded"
            @removeWidget="removeWidget"
            @enlargeWidget="enlargeWidget"
          >
            <template v-slot:widget-filters>
              <div
                v-if="widget?.filters?.includes('users')"
                class="widget-filter"
              >
                <GroupOrUserSelect
                  v-model="selectedUsersForWidget[widget._id]"
                  :id="widget._id"
                  :allow-only-first-level-data="
                    allowOnlyFirstLevelData[widget._id]
                  "
                  @change="
                    (value) => handleWidgetUsersChange(widget._id, value)
                  "
                />
              </div>
            </template>
            <template v-slot:widget-content>
              <component
                :is="widget.component"
                :widget-id="widget._id"
                :filtered-users="filteredUsersForWidget[widget._id]"
                :group-or-user="groupOrUser[widget._id]"
                @updateFilterLevel="handleFilterLevel(widget._id, $event)"
                @clearGroup="handleClearGroup"
                @contentLoaded="contentLoaded"
              ></component>
              <div class="loader-container">
                <div v-if="!widget.loaded" class="loader-center">
                  <div class="loader"></div>
                </div>
              </div>
            </template>
          </Widget>
        </div>
      </draggable>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable-rp";
import Widget from "./Widget.vue";
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
import GroupOrUserSelect from "@/components/FormElements/GroupOrUserSelect.vue";
import {
  getComponentName,
  getWidgetFilters,
} from "@/helpers/dashboard/widgetConfig";
import BirthdaysWidget from "./widgets/BirthdaysWidget.vue";
import StatisticsWidget from "./widgets/StatisticsWidget.vue";
import TurnoverWidget from "./widgets/TurnoverWidget.vue";
import ContactsWidget from "./widgets/ContactsWidget.vue";
import ScorecardWidget from "./widgets/ScorecardWidget.vue";

export default {
  name: "GridContent",
  components: {
    Widget,
    draggable,
    GroupOrUserSelect,
    BirthdaysWidget,
    ScorecardWidget,
    ContactsWidget,
    TurnoverWidget,
    StatisticsWidget,
  },
  props: {
    addedWidgets: {
      type: Array,
      default: () => [],
    },
    userDashboard: {
      type: Object,
    },
  },
  data() {
    return {
      widgetSelectedUsers: {},
      localWidgets: [],
      brokerId: "",
      currentActiveFilter: null,
      selectedUsersForWidget: {},
      filteredUsersForWidget: {},
      activeWidgetId: null,
      activeWidgetOriginalSize: { width: 0, height: 0 },
      enlargedWidget: null,
      allowOnlyFirstLevelData: {},
      groupOrUser: {},
      dashboardLoaded: false
    };
  },
  mounted() {
    this.initializeWidgetsFromDashboards();
    this.updateFilteredUsers();
  },
  computed: {
    ...mapGetters(["user", "groups", "getWidgetUsers"]),
  },
  watch: {
    addedWidgets(newWidgets) {
      this.localWidgets.push(...newWidgets);
    },
    selectedUsersForWidget: {
      handler(newVal) {
        this.groupOrUser = newVal;
        this.updateFilteredUsers();
      },
      deep: true,
      immediate: true,
    },
    userDashboard: {
      immediate: true,
      deep: true,
      handler() {
        this.initializeWidgetsFromDashboards();
      },
    },
  },
  methods: {
    ...mapActions(["setWidgetUsers"]),
    handleFilterLevel(widgetId, allowOnlyFirstLevel) {
      let isOnlyFirstLevel;
      if (allowOnlyFirstLevel !== undefined && "value" in allowOnlyFirstLevel) {
        isOnlyFirstLevel = allowOnlyFirstLevel.value;
      }
      this.$set(this.allowOnlyFirstLevelData, widgetId, isOnlyFirstLevel);
    },
    handleClearGroup(widgetId) {
      this.$set(this.selectedUsersForWidget, widgetId, null);
    },
    contentLoaded(widgetId) {
      const widgetIndex = this.localWidgets.findIndex(
        (w) => w._id === widgetId
      );
      if (widgetIndex !== -1) {
        this.$set(this.localWidgets, widgetIndex, {
          ...this.localWidgets[widgetIndex],
          loaded: true,
        });
      }
    },
    addWidgets() {
      this.$emit("addWidgets");
    },
    enlargeWidget(event, widget) {
      let widgetElement = event.currentTarget.closest(".widget-wrapper");
      if (widgetElement) {
        this.activeWidgetOriginalSize = {
          width: widgetElement.offsetWidth + "px",
          height: widgetElement.offsetHeight + "px",
        };
        this.enlargedWidget = { ...widget };
        this.activeWidgetId = widget._id;
      } else {
        console.error("Could not find widget element");
      }
    },
    closeEnlargedWidget() {
      this.enlargedWidget = null;
      this.activeWidgetId = null;
    },
    async removeWidget(widgetId) {
      try {
        const dashboard = this.userDashboard;
        const dashboardId = dashboard._id;

        const response = await axios.post(
          `/api/dashboard/remove-dashboard-widget/${dashboardId}/${widgetId}`
        );

        if (response.status === 200) {
          const index = this.localWidgets.findIndex((w) => w._id === widgetId);
          if (index !== -1) {
            this.localWidgets.splice(index, 1);
          }
          this.toastSuccess(this.$t("WidgetRemoved"));
        } else {
          this.toastSuccess(this.$t("WidgetRemovalUnsuccsessful"));
        }
      } catch (error) {
        console.error("Error removing widget: ", error);
      }
    },
    onDragEnd(event) {
      let newOrder = [...this.localWidgets];
      const movedItem = newOrder.splice(event.oldIndex, 1)[0];
      newOrder.splice(event.newIndex, 0, movedItem);
      this.updateWidgetsOrder();
    },
    async updateWidgetsOrder() {
      const widgetOrder = this.localWidgets.map((widget) => widget._id);
      try {
        await axios.post(
          `/api/dashboard/update-widgets-order/${this.userDashboard._id}`,
          {
            widgetOrder,
          }
        );
      } catch (error) {
        console.error("Error updating widget order: ", error);
      }
    },
    handleWidgetUsersChange(widgetId, users) {
      this.$set(this.selectedUsersForWidget, widgetId, users);
      this.setWidgetUsers({ widgetId, users });
    },
    updateFilteredUsers() {
      let filteredUsers = {};
      if (this.localWidgets && this.localWidgets.length > 0) {
        this.localWidgets.forEach((widget) => {
          const widgetId = widget._id;
          const selection = this.selectedUsersForWidget[widgetId];
          if (selection && Object.keys(selection).length > 0) {
            if (selection.type === "group") {
              const group = this.groups[selection.value];
              if (group && group.users) {
                const userIds = Object.keys(group.users);
                filteredUsers[widgetId] = Object.values(this.users).filter(
                  (user) => userIds.includes(user._id)
                );
              }
            } else if (selection.type === "user") {
              const user = this.users.find(
                (user) => user._id === selection.value
              );
              if (user) {
                filteredUsers[widgetId] = [user];
              }
            }
          } else {
            filteredUsers[widgetId] = Object.values(this.users);
          }
        });
      }
      this.filteredUsersForWidget = filteredUsers;
    },
    async initializeWidgetsFromDashboards() {
      const defaultDashboard = this.userDashboard;
      if (defaultDashboard && defaultDashboard.widgets) {
        const widgets = await Promise.all(
          defaultDashboard.widgets.map(async (widget) => {
            const component = await getComponentName(widget.component);
            return {
              ...widget,
              component: component,
              filters: getWidgetFilters(widget.component),
            };
          })
        );
        this.dashboardLoaded = true
        this.localWidgets = widgets;
        this.localWidgets.forEach((widget) => {
          let initialUsers = this.getWidgetUsers(widget._id);
          if (initialUsers && Object.keys(initialUsers).length > 0) {
            this.$set(this.selectedUsersForWidget, widget._id, initialUsers);
          } else {
            this.$set(this.selectedUsersForWidget, widget._id, null);
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.loader-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.enlargedWidget {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1001;
}

.loader::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: conic-gradient(#0000 10%, #a0a0a0);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 8px), #000 0);
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.selected {
  background-color: rgba(255, 92, 1, 1) !important;
  color: white !important;
}

.birthday-selection {
  background-color: rgba(244, 245, 247, 1);
  color: rgba(147, 149, 151, 1);
}

.birthday-detail.today {
  color: rgba(255, 92, 1, 1) !important;
}

.birthday-detail {
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
  color: rgba(147, 149, 151, 1);
}

.birthday-name {
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  font-family: Geomanist;
}

.birthday-credentials {
  display: flex;
  flex-direction: column;
}

.month-header {
  position: sticky;
  top: 0;
  background-color: rgba(230, 232, 236, 1);
  font-weight: 500;
  font-family: inter;
  color: rgba(147, 149, 151, 1);
  font-size: 12px;
}

.month-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.month-header {
  position: sticky;
  top: 0;
  background: white;
}

.birthday-date {
  border-radius: 24px;
  height: 24px;
  padding: 4px 10px;
  display: flex;
  align-items: center;
  background-color: rgba(230, 232, 236, 1);
}

.birthday-date.today,
.selected-birthdays {
  background-color: rgba(255, 92, 1, 1);
  color: white;
}

.birthdays-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow-y: auto;
  flex: 1;
  padding: 0 0 20px 0;
}

.birthday-field {
  border-radius: 12px;
  padding: 6px 14px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(244, 245, 247, 1);
}

.birthday-field.today {
  background-color: rgba(255, 240, 240, 1);
}

.drag-area {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(237px, 1fr));
  gap: 12px;
}

@media (min-width: 430px) {
  .drag-area {
    grid-template-columns: repeat(auto-fill, minmax(194px, 194px));
  }
}

.widget-grid-content {
  background-color: #f4f5f7;
  overflow-x: scroll;
  overflow-y: hidden;
  padding: 24px 48px 24px 112px;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.scrollable {
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 12px;
}

@media screen and (max-height: 900px) {
  .deal-grid-content {
    height: 40em;
  }
}

.widget-grid-container {
  width: 100%;
}

.content-loading {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
}
</style>
