<template>
  <div class="widget-wrapper"></div>
</template>

<script>
export default {
  name: "ContactsAdded",
  components: {},
  props: {
    widgetId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {};
  },
  mounted() {
    this.$emit("contentLoaded", this.widgetId);
  },
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.widget-wrapper {
  display: flex;
  gap: 16px;
  height: 410px;
}
.birthday-selections {
  display: flex;
  gap: 8px;
}

.selected {
  background-color: rgba(255, 92, 1, 1) !important;
  color: white !important;
}

.birthday-selection {
  background-color: rgba(244, 245, 247, 1);
  color: rgba(147, 149, 151, 1);
  padding: 4px 16px;
}

.birthday-detail.today {
  color: rgba(255, 92, 1, 1) !important;
}

.birthday-detail {
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
  color: rgba(147, 149, 151, 1);
}

.birthday-name {
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  font-family: Geomanist;
}

.birthday-credentials {
  display: flex;
  flex-direction: column;
}

.month-header {
  position: sticky;
  top: 0;
  background-color: rgba(230, 232, 236, 1);
  font-weight: 500;
  font-family: inter;
  color: rgba(147, 149, 151, 1);
  font-size: 12px;
}

.month-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.month-header {
  position: sticky;
  top: 0;
  background: white;
}

.birthday-date {
  border-radius: 24px;
  height: 24px;
  padding: 4px 10px;
  display: flex;
  align-items: center;
  background-color: rgba(230, 232, 236, 1);
}

.birthday-date.today,
.selected-birthdays {
  background-color: rgba(255, 92, 1, 1);
  color: white;
}

.birthdays-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow-y: auto;
  flex: 1;
  padding: 0 0 20px 0;
}

.birthday-field {
  border-radius: 12px;
  padding: 6px 14px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(244, 245, 247, 1);
}

.birthday-field.today {
  background-color: rgba(255, 240, 240, 1);
}

.drag-area {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(237px, 1fr));
  grid-auto-rows: 237px;
  gap: 12px;
}

/* w1h1 -> width 1fraction, height 1fraction */
.w1h1 {
  grid-column: span 1;
  grid-row: span 1;
}

.w2h2 {
  grid-column: span 2;
  grid-row: span 2;
}

@media (min-width: 448px) {
  .drag-area {
    grid-template-columns: repeat(auto-fill, minmax(194px, 194px));
  }
}

.widget-grid-content {
  background-color: #f4f5f7;
  overflow-x: scroll;
  overflow-y: hidden;
  padding: 24px 48px 24px 112px;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.scrollable {
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 12px;
}

@media screen and (max-height: 900px) {
  .deal-grid-content {
    height: 40em;
  }
}

.widget-grid-container {
  width: 100%;
}
</style>
