<template>
  <div class="widget-wrapper">
    <!-- Birthday options -->
    <div class="birthday-selections">
      <button
        class="birthday-selection"
        :class="{
          selected: selectedCategory === 'colleagues',
        }"
        @click="changeCategory('colleagues')"
      >
        {{ $t("Colleagues") }}
      </button>
      <button
        class="birthday-selection"
        :class="{
          selected: selectedCategory === 'contacts',
        }"
        @click="changeCategory('contacts')"
      >
        {{ $t("Contacts") }}
      </button>
    </div>
    <!-- Birthdays -->
    <div
      class="birthdays-wrapper"
      ref="birthdaysWrapper-contacts"
      v-if="selectedCategory === 'contacts' && birthdays && birthdays.length"
    >
      <div
        v-for="(group, index) in groupedBirthdays"
        :key="index"
        class="month-group"
      >
        <div class="month-header">{{ $t(group.month) }} {{ group.year }}</div>
        <div
          v-for="birthday in group.birthdays"
          :key="birthday._id"
          :data-id="birthday._id"
          class="birthday-field"
          :class="{
            today: isToday(birthday.birthday),
            'next-upcoming':
              isNextUpcomingBirthday(birthday.birthday) &&
              !isToday(birthday.birthday),
          }"
        >
          <div class="birthday-credentials">
            <div class="birthday-name">
              {{ birthday.customerName }}
            </div>
            <div
              class="birthday-detail"
              :class="{ today: isToday(birthday.birthday) }"
            >
              {{ birthday.organization }}
            </div>
          </div>
          <div
            class="birthday-date"
            :class="{
              today: isToday(birthday.birthday),
            }"
          >
            {{ formatDate(birthday.birthday) }}
          </div>
        </div>
      </div>
    </div>
    <div
      class="birthdays-wrapper"
      ref="birthdaysWrapper-colleagues"
      v-if="selectedCategory === 'colleagues' && users && users.length"
    >
      <div
        v-for="(group, index) in groupedBirthdays"
        :key="index"
        class="month-group"
      >
        <div class="month-header">{{ $t(group.month) }} {{ group.year }}</div>
        <div
          v-for="birthday in group.birthdays"
          :key="birthday._id"
          :data-id="birthday._id"
          class="birthday-field"
          :class="{
            today: isToday(birthday.birthday),
            'next-upcoming':
              isNextUpcomingBirthday(birthday.birthday) &&
              !isToday(birthday.birthday),
          }"
        >
          <div class="birthday-credentials-wrapper">
            <div
              class="profile-pic-wrapper"
              v-if="
                birthday &&
                birthday.profilePicture &&
                birthday.profilePicture[0] &&
                birthday.profilePicture[0].name
              "
            >
              <img class="profile-pic" :src="birthday.profilePicture[0].name" />
            </div>
            <div class="alt-profile-pic-wrapper" v-else>
              <img
                class="alt-profile-pic"
                src="../../../assets/images/user.svg"
                alt="Profile Picture"
              />
            </div>
            <div class="birthday-credentials">
              <div class="birthday-name">
                {{ birthday.name }}
              </div>
              <div
                class="birthday-detail"
                :class="{ today: isToday(birthday.birthday) }"
              >
                {{ birthday.defaultGroup.name }}
              </div>
            </div>
          </div>
          <div
            class="birthday-date"
            :class="{
              today: isToday(birthday.birthday),
            }"
          >
            {{ formatDate(birthday.birthday) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "BirthdayWidget",
  components: {},
  props: {
    widgetId: {
      type: String,
      required: true,
    },
    filteredUsers: {
      type: Array,
      default: undefined,
    },
    groupOrUser: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      birthdays: [],
      groupedBirthdays: [],
      defaultGroup: "",
    };
  },
  async mounted() {
    await this.fetchUpcomingContactsBirthdays();
    const categoryFromStore = this.getSelectedBirthdayCategory(this.widgetId);
    this.selectedCategory = categoryFromStore || "colleagues";
    const allowOnlyFirstLevel = this.allowOnlyFirstLevel;
    this.$emit("updateFilterLevel", {
      widgetId: this.widgetId,
      value: allowOnlyFirstLevel,
    });
    this.getBirthdays();
  },
  computed: {
    ...mapGetters([
      "getSelectedBirthdayCategory",
      "getAllowOnlyFirstLevelForWidget",
      "user",
      "users",
    ]),
    colleagues() {
      return this.filteredUsers || this.users;
    },
    selectedCategory: {
      get() {
        if (this.getSelectedBirthdayCategory(this.widgetId).length > 0) {
          return this.getSelectedBirthdayCategory(this.widgetId);
        } else {
          return "colleagues";
        }
      },
      set(value) {
        this.updateSelectedBirthdayCategory({
          widgetId: this.widgetId,
          category: value,
        });
      },
    },
    allowOnlyFirstLevel() {
      return this.getAllowOnlyFirstLevelForWidget(this.widgetId);
    },
  },
  watch: {
    filteredUsers: {
      async handler(newVal) {
        if (newVal && this.selectedCategory === "colleagues") {
          this.getBirthdays();
        } else if (newVal && this.selectedCategory === "contacts") {
          await this.fetchUpcomingContactsBirthdays();
          this.getBirthdays();
        }
      },
      deep: true,
    },
    selectedCategory() {
      this.getBirthdays();
    },
    groupedBirthdays(newValue) {
      if (newValue && newValue.length > 0) {
        this.$nextTick(() => {
          this.scrollIntoToday();
        });
      }
    },
  },
  methods: {
    ...mapActions([
      "updateSelectedBirthdayCategory",
      "setAllowOnlyFirstLevelForWidget",
    ]),
    async changeCategory(newCategory) {
      const widgetId = this.widgetId;
      if (
        newCategory === "contacts" &&
        this.groupOrUser &&
        this.groupOrUser.type === "group"
      ) {
        this.$emit("clearGroup", this.widgetId);
      }
      let allowOnlyFirstLevel = newCategory === "contacts";
      this.updateSelectedBirthdayCategory({
        widgetId,
        category: newCategory,
      });
      this.setAllowOnlyFirstLevelForWidget({
        widgetId,
        allowOnlyFirstLevel,
      });
      this.$emit("updateFilterLevel", { value: allowOnlyFirstLevel });
      if (newCategory === "colleagues") {
        this.getBirthdays();
      } else if (newCategory === "contacts") {
        await this.fetchUpcomingContactsBirthdays();
        this.getBirthdays();
      }
      this.$nextTick(() => {
        this.scrollIntoToday();
      });
    },
    getBirthdays() {
      const groups = {};
      const currentYear = new Date().getFullYear();

      let birthdaysArray;
      if (this.selectedCategory === "contacts") {
        birthdaysArray = this.birthdays;
      } else if (this.selectedCategory === "colleagues") {
        let birthdayUsers = this.colleagues
          .filter((user) => user.birthday && user.birthday.trim() !== "")
          .map((user) => {
            user.defaultGroup = user.groups.find(
              (group) => group.userDefault === true
            );
            return user;
          });

        birthdaysArray = birthdayUsers;
      }
      const sortedBirthdays = birthdaysArray.slice().sort((a, b) => {
        const dateA = new Date(a.birthday);
        const dateB = new Date(b.birthday);
        dateA.setFullYear(currentYear);
        dateB.setFullYear(currentYear);
        return dateA - dateB;
      });
      sortedBirthdays.forEach((birthday) => {
        const date = new Date(birthday.birthday);
        date.setFullYear(currentYear);
        const month = date.toLocaleString("default", { month: "long" });
        const year = date.getFullYear();
        const monthKey = month + " " + year;

        if (!groups[monthKey]) {
          groups[monthKey] = {
            month: month,
            year: year,
            birthdays: [],
          };
        }
        groups[monthKey].birthdays.push(birthday);
      });

      const groupedArray = Object.values(groups);
      groupedArray.sort((a, b) => new Date(a.month) - new Date(b.month));

      const today = new Date();
      const month = today.toLocaleString("default", { month: "long" });
      const year = today.getFullYear();
      const todayKey = `${month} ${year}`;

      const currentMonthGroupIndex = this.groupedBirthdays.findIndex(
        (group) => group.month === todayKey
      );
      if (currentMonthGroupIndex > -1) {
        const currentMonthGroup = this.groupedBirthdays.splice(
          currentMonthGroupIndex,
          1
        )[0];
        this.groupedBirthdays.unshift(currentMonthGroup);
      }

      this.groupedBirthdays = groupedArray;
    },
    scrollIntoToday() {
      this.$nextTick(() => {
        const container =
          this.$refs[`birthdaysWrapper-${this.selectedCategory}`];

        if (container) {
          let scrollToElement =
            container.querySelector(".birthday-field.today") ||
            container.querySelector(".birthday-field.next-upcoming");

          if (scrollToElement) {
            const topPosition = scrollToElement.offsetTop - container.offsetTop;
            container.scrollTop = topPosition - 28;
          }
        }
      });
    },
    findNextUpcomingElementId() {
      let closestUpcomingId = null;
      let closestDiff = Infinity;
      const today = new Date();

      this.birthdays.forEach((birthday) => {
        const birthdayDate = new Date(birthday.date);
        const diff = birthdayDate - today;
        if (diff > 0 && diff < closestDiff) {
          closestUpcomingId = birthday._id;
          closestDiff = diff;
        }
      });

      return closestUpcomingId;
    },
    scrollToElement(container, element) {
      const adjustment = 28;
      const elementRect = element.getBoundingClientRect();
      const containerRect = container.getBoundingClientRect();
      const topPos = elementRect.top - containerRect.top - adjustment;
      container.scrollTop = topPos;
    },
    isToday(birthdayDate) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const birthday = new Date(birthdayDate);
      birthday.setFullYear(today.getFullYear());
      return today.toDateString() === birthday.toDateString();
    },
    isNextUpcomingBirthday(birthdayDate) {
      const upcomingBirthdayObj = this.findNextUpcomingBirthday();
      if (!upcomingBirthdayObj) return false;

      const upcomingBirthdayDate = new Date(upcomingBirthdayObj.birthday);
      const birthday = new Date(birthdayDate);

      upcomingBirthdayDate.setFullYear(new Date().getFullYear());
      birthday.setFullYear(new Date().getFullYear());

      return upcomingBirthdayDate.toDateString() === birthday.toDateString();
    },
    findNextUpcomingBirthday() {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      let closestBirthday = null;
      let closestBirthdayDiff = Infinity;

      let sourceArray;
      if (this.selectedCategory === "contacts") {
        sourceArray = this.birthdays;
      } else if (this.selectedCategory === "colleagues") {
        sourceArray = this.users.filter(
          (user) => user.birthday && user.birthday.trim() !== ""
        );
      }

      sourceArray.forEach((item) => {
        const birthdayDate = new Date(item.birthday);
        birthdayDate.setFullYear(today.getFullYear());
        if (birthdayDate < today) {
          birthdayDate.setFullYear(today.getFullYear() + 1);
        }
        const diff = birthdayDate - today;
        if (diff < closestBirthdayDiff) {
          closestBirthdayDiff = diff;
          closestBirthday = item;
        }
      });

      return closestBirthday;
    },
    formatDate(dateStr) {
      if (!dateStr) return "";
      const date = new Date(dateStr);
      const today = new Date();
      const tomorrow = new Date();
      tomorrow.setDate(today.getDate() + 1);

      const comparisonDate = new Date(date);
      comparisonDate.setFullYear(today.getFullYear());

      if (
        comparisonDate.getMonth() === today.getMonth() &&
        comparisonDate.getDate() === today.getDate()
      ) {
        return this.$t("Today");
      }

      if (
        comparisonDate.getMonth() === tomorrow.getMonth() &&
        comparisonDate.getDate() === tomorrow.getDate()
      ) {
        return this.$t("Tomorrow");
      }

      const currentLocale = this.$i18n.locale;
      const localeMap = {
        en: "en-GB",
        et: "et-ET",
      };
      const localeFormat = localeMap[currentLocale] || "en-GB";
      return date.toLocaleDateString(localeFormat, {
        day: "2-digit",
        month: "short",
      });
    },
    async fetchUpcomingContactsBirthdays() {
      try {
        let selectedBroker;
        if (
          this.filteredUsers &&
          this.filteredUsers.length === 1 &&
          this.filteredUsers[0]._id
        ) {
          selectedBroker = this.filteredUsers[0]._id;
        }
        let filters = {
          birthday: "from_today",
        };
        if (selectedBroker) {
          filters.brokerId = { value: selectedBroker };
        }
        const response = await axios.post("/api/contacts-pagination/1", {
          filters: filters,
          limit: 20,
          sortField: "birthday",
          sortOrder: 1,
        });
        if (response && response.data && this.widgetId) {
          this.birthdays = response.data.filteredContacts;
          this.$emit("contentLoaded", this.widgetId);
          this.scrollIntoToday();
        }
      } catch (error) {
        console.error("Error fetching upcoming birthdays:", error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.alt-profile-pic-wrapper {
  width: 28px;
  height: 28px;
  border-radius: 28px;
  background-color: rgb(230, 232, 236);
  display: flex;
  align-items: center;
  justify-content: center;
}

.alt-profile-pic {
  width: 16px;
  height: 16px;
}

.profile-pic {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.birthday-credentials-wrapper {
  display: flex;
  gap: 12px;
}

.profile-pic-wrapper {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  overflow: hidden;
}

.widget-wrapper {
  display: flex;
  gap: 16px;
  height: 410px;
}

.birthday-selections {
  display: flex;
  gap: 8px;
}

.selected {
  background-color: rgba(255, 92, 1, 1) !important;
  color: white !important;
}

.birthday-selection {
  background-color: rgba(244, 245, 247, 1);
  color: rgba(147, 149, 151, 1);
  padding: 4px 16px;
}

.birthday-detail.today {
  color: rgba(255, 92, 1, 1) !important;
}

.birthday-detail {
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
  color: rgba(147, 149, 151, 1);
}

.birthday-name {
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  font-family: Geomanist;
}

.birthday-credentials {
  display: flex;
  flex-direction: column;
}

.month-header {
  position: sticky;
  top: 0;
  background-color: rgba(230, 232, 236, 1);
  font-weight: 500;
  font-family: inter;
  color: rgba(147, 149, 151, 1);
  font-size: 12px;
}

.month-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.month-header {
  position: sticky;
  top: 0;
  background: white;
}

.birthday-date {
  border-radius: 24px;
  height: 24px;
  padding: 4px 10px;
  display: flex;
  align-items: center;
  background-color: rgba(230, 232, 236, 1);
}

.birthday-date.today,
.selected-birthdays {
  background-color: rgba(255, 92, 1, 1);
  color: white;
}

.birthdays-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow-y: auto;
  flex: 1;
  padding: 0 0 20px 0;
}

.birthday-field {
  border-radius: 12px;
  padding: 6px 14px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(244, 245, 247, 1);
}

.birthday-field.today {
  background-color: rgba(255, 240, 240, 1);
}

.drag-area {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(237px, 1fr));
  grid-auto-rows: 237px;
  gap: 12px;
}

@media (min-width: 448px) {
  .drag-area {
    grid-template-columns: repeat(auto-fill, minmax(194px, 194px));
  }
}

.widget-grid-content {
  background-color: #f4f5f7;
  overflow-x: scroll;
  overflow-y: hidden;
  padding: 24px 48px 24px 112px;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.scrollable {
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 12px;
}

@media screen and (max-height: 900px) {
  .deal-grid-content {
    height: 40em;
  }
}

.widget-grid-container {
  width: 100%;
}
</style>
