var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "widget-wrapper"
  }, [_c('div', {
    staticClass: "birthday-selections"
  }, [_c('button', {
    staticClass: "birthday-selection",
    class: {
      selected: _vm.selectedCategory === 'colleagues'
    },
    on: {
      "click": function ($event) {
        return _vm.changeCategory('colleagues');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Colleagues")) + " ")]), _c('button', {
    staticClass: "birthday-selection",
    class: {
      selected: _vm.selectedCategory === 'contacts'
    },
    on: {
      "click": function ($event) {
        return _vm.changeCategory('contacts');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Contacts")) + " ")])]), _vm.selectedCategory === 'contacts' && _vm.birthdays && _vm.birthdays.length ? _c('div', {
    ref: "birthdaysWrapper-contacts",
    staticClass: "birthdays-wrapper"
  }, _vm._l(_vm.groupedBirthdays, function (group, index) {
    return _c('div', {
      key: index,
      staticClass: "month-group"
    }, [_c('div', {
      staticClass: "month-header"
    }, [_vm._v(_vm._s(_vm.$t(group.month)) + " " + _vm._s(group.year))]), _vm._l(group.birthdays, function (birthday) {
      return _c('div', {
        key: birthday._id,
        staticClass: "birthday-field",
        class: {
          today: _vm.isToday(birthday.birthday),
          'next-upcoming': _vm.isNextUpcomingBirthday(birthday.birthday) && !_vm.isToday(birthday.birthday)
        },
        attrs: {
          "data-id": birthday._id
        }
      }, [_c('div', {
        staticClass: "birthday-credentials"
      }, [_c('div', {
        staticClass: "birthday-name"
      }, [_vm._v(" " + _vm._s(birthday.customerName) + " ")]), _c('div', {
        staticClass: "birthday-detail",
        class: {
          today: _vm.isToday(birthday.birthday)
        }
      }, [_vm._v(" " + _vm._s(birthday.organization) + " ")])]), _c('div', {
        staticClass: "birthday-date",
        class: {
          today: _vm.isToday(birthday.birthday)
        }
      }, [_vm._v(" " + _vm._s(_vm.formatDate(birthday.birthday)) + " ")])]);
    })], 2);
  }), 0) : _vm._e(), _vm.selectedCategory === 'colleagues' && _vm.users && _vm.users.length ? _c('div', {
    ref: "birthdaysWrapper-colleagues",
    staticClass: "birthdays-wrapper"
  }, _vm._l(_vm.groupedBirthdays, function (group, index) {
    return _c('div', {
      key: index,
      staticClass: "month-group"
    }, [_c('div', {
      staticClass: "month-header"
    }, [_vm._v(_vm._s(_vm.$t(group.month)) + " " + _vm._s(group.year))]), _vm._l(group.birthdays, function (birthday) {
      return _c('div', {
        key: birthday._id,
        staticClass: "birthday-field",
        class: {
          today: _vm.isToday(birthday.birthday),
          'next-upcoming': _vm.isNextUpcomingBirthday(birthday.birthday) && !_vm.isToday(birthday.birthday)
        },
        attrs: {
          "data-id": birthday._id
        }
      }, [_c('div', {
        staticClass: "birthday-credentials-wrapper"
      }, [birthday && birthday.profilePicture && birthday.profilePicture[0] && birthday.profilePicture[0].name ? _c('div', {
        staticClass: "profile-pic-wrapper"
      }, [_c('img', {
        staticClass: "profile-pic",
        attrs: {
          "src": birthday.profilePicture[0].name
        }
      })]) : _c('div', {
        staticClass: "alt-profile-pic-wrapper"
      }, [_c('img', {
        staticClass: "alt-profile-pic",
        attrs: {
          "src": require("../../../assets/images/user.svg"),
          "alt": "Profile Picture"
        }
      })]), _c('div', {
        staticClass: "birthday-credentials"
      }, [_c('div', {
        staticClass: "birthday-name"
      }, [_vm._v(" " + _vm._s(birthday.name) + " ")]), _c('div', {
        staticClass: "birthday-detail",
        class: {
          today: _vm.isToday(birthday.birthday)
        }
      }, [_vm._v(" " + _vm._s(birthday.defaultGroup.name) + " ")])])]), _c('div', {
        staticClass: "birthday-date",
        class: {
          today: _vm.isToday(birthday.birthday)
        }
      }, [_vm._v(" " + _vm._s(_vm.formatDate(birthday.birthday)) + " ")])]);
    })], 2);
  }), 0) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }