// widgetConfig

export const componentMap = {
  BirthdaysWidget: () => import('@/components/Dashboard/widgets/BirthdaysWidget.vue'),
  ScorecardWidget: () => import('@/components/Dashboard/widgets/ScorecardWidget.vue'),
  StatisticsWidget: () => import('@/components/Dashboard/widgets/StatisticsWidget.vue'),
  TurnoverWidget: () => import('@/components/Dashboard/widgets/TurnoverWidget.vue'),
  ContactsWidget: () => import('@/components/Dashboard/widgets/ContactsWidget.vue'),
}

export const filterMap = {
  BirthdaysWidget: ["users"],
  ScorecardWidget: [""],
  StatisticsWidget: [""],
  TurnoverWidget: [""],
  ContactsWidget: [""],
};

export function getComponentName(componentName) {
  const componentLoader = componentMap[componentName];
  if (componentLoader) {
    return componentLoader().then(c => c.default || c);
  }
  return Promise.resolve(null);
}

export function getWidgetFilters(widgetComponentName) {
  return filterMap[widgetComponentName] || [];
}
