import { render, staticRenderFns } from "./TurnoverWidget.vue?vue&type=template&id=0b06a0ce&scoped=true"
import script from "./TurnoverWidget.vue?vue&type=script&lang=js"
export * from "./TurnoverWidget.vue?vue&type=script&lang=js"
import style0 from "./TurnoverWidget.vue?vue&type=style&index=0&id=0b06a0ce&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b06a0ce",
  null
  
)

export default component.exports